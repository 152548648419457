<script>
  export let project;

  export function nameId(name) {
    if (name !== undefined) {
      // console.log("NAME", name);
      return name.replace(/\s/g, "_");
    }

    return name;
  }
  // import loading from "./loading.gif";
  const loading = "https://cdn.resmana.com/file/tomate/kvn/loading.gif";

  import "@jamescoyle/svg-icon";
  import { mdiFileImage, mdiGithub, mdiWeb, mdiVideo } from "@mdi/js";
</script>

<style lang="scss">
  .project {
    display: flex;
    // padding: 20px;
    // padding: 1px;

    // min-height: 200px;

    border: 1px solid #333;
    position: relative;
    // margin-top: 10px;
    // z-index: 2;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 50%;
    //   left: -20px;
    //   border: 1px solid black;
    //   border-bottom: 1px solid white;
    //   padding: 2px 8px;
    //   background: #fff;
    //   // font-weight: bold;
    //   z-index: 99999;
    // }

    // &::after {
    //   content: attr(date);
    //   position: absolute;
    //   top: -23px;
    //   left: -1px;
    //   border: 1px solid black;
    //   border-bottom: 1px solid white;
    //   padding: 2px 8px;
    //   background: #fff;
    //   // font-weight: bold;
    //   z-index: 99999;
    // }

    .image {
      width: calc(100% / 2.8);
      height: auto;
      flex: none;
      background-size: cover;
      text-align: center;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      background-image: url(https://cdn.resmana.com/file/tomate/kvn/loading.gif);

      border-right: 1px solid black;

      &:hover {
        &::before {
          // width: 200px;
          // height: 200px;
          padding: 4px 8px;
          position: absolute;
          bottom: 1px;
          left: 1px;
          content: "Click to expand image";
          color: white;
          background: rgba(0, 0, 0, 0.5);
          z-index: 999;
        }
      }

      // &:not([data-loaded="true"])::after {
      //   border-radius: 50%;
      //   width: 40px;
      //   height: 40px;

      //   color: white;
      //   background: rgba(0, 0, 0, 0.5);
      //   content: "";

      //   // margin: 60px auto;
      //   font-size: 10px;
      //   position: absolute;
      //   top: 10px;
      //   left: 10px;

      //   $border: 4px solid;
      //   $color: black;

      //   text-indent: -9999em;
      //   border-top: $border $color;
      //   border-right: $border $color;
      //   border-bottom: $border $color;
      //   border-left: 4px solid #f5f6f7;
      //   -webkit-transform: translateZ(0);
      //   -ms-transform: translateZ(0);
      //   transform: translateZ(0);
      //   -webkit-animation: load8 1.1s infinite linear;
      //   animation: load8 1.1s infinite linear;
      // }

      // &:focus,
      // &:active {
      //   &::before {
      //     display: none;
      //   }
      //   // &::after {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   content: " ";
      //   width: 100%;
      //   // height: 50vh;
      //   margin-top: 0;
      //   background-color: rgba(0, 0, 0, 0.5);
      //   z-index: 9999;
      //   // background-size: cover;
      //   // background-position: center center;
      //   // }
      // }
    }

    .bold {
      font-weight: bold;
    }

    .info {
      width: 100%;
      padding: 13px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > div > .description {
        margin: 8px 0px;
      }
    }

    .name {
      width: 100%;
      font-weight: bold;
      font-size: 1.2em;
    }

    .project-type {
      margin-left: 5px;
      float: right;
      font-weight: normal;
    }

    .sections {
      // display: flex;
      // justify-content: items-center;

      .section {
        margin-top: 8px;
        // background: rgba(100, 100, 100, 0.1);
        border: 1px dotted rgba(100, 100, 100, 1);
        padding: 4px 8px;

        &:hover {
          background: #eee;
        }

        .information {
          display: flex;

          &:hover {
            background: rgba(255, 255, 255, 1);
          }

          svg {
            width: 18px;
            height: 18px;
            // display: inline-block;
            display: inline;
            margin-right: 4px;
          }

          a {
            font-size: 1em;
            display: flex;
            text-decoration: none !important;
          }

          a,
          a:link,
          a:visited {
            > p.description {
              margin-left: 4px;
              color: #444;
              display: inline;
            }
          }
        }

        hr {
          background: none;
          border-bottom: 1px solid #ccc;
        }

        .title {
          font-size: 1.05em;
          margin-bottom: 8px;
        }

        &.type {
          float: right;
        }
      }
    }

    .labels {
      margin-top: 4px;
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .label {
        padding: 2px 4px;
        margin-right: 4px;
        margin-top: 4px;

        &.framework {
          // background: #ddd;
          border: 1px dashed #333;
        }

        &.language {
          // background: rgb(195, 195, 224);
          border: 1px solid #333;
        }

        &.platform {
          // background: rgb(119, 159, 185);
          border: 1px dotted #333;
        }

        &.fork {
          color: rgb(38, 115, 167);
          // background: rgb(38, 115, 167);
          font-weight: bold;
        }

        &.open {
          // color: white;
          // background: rgb(5, 53, 5);
          font-weight: bold;
          // line-height: 40px;
        }

        &.closed {
          // color: white;
          // background: rgb(34, 11, 11);
        }
      }

      .types {
        margin-left: auto;
      }

      // .type {
      //   float: right;
      //   padding: 2px 4px 6px 4px;
      //   font-weight: normal;
      //   color: white;
      //   font-size: 0.9em;
      //   line-height: 0.9em;
      //   text-transform: uppercase;
      //   margin: 0;

      //   &.fork {
      //     background: rgb(38, 115, 167);
      //   }

      //   &.open {
      //     background: rgb(5, 53, 5);
      //   }

      //   &.closed {
      //     background: rgb(34, 11, 11);
      //   }
      // }
    }

    .heading {
      // background: red;
      float: right;
      display: none;
    }

    // a.image {
    // &::before {
    //   content: "";
    // }
    // }

    @-webkit-keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
</style>

<div
  class="project"
  month={project.date.month}
  year={project.date.year}
  date={`${project.date.month}/${project.date.year}`}>
  <!-- side image -->
  {#if project.img}
    <a
      class="image"
      title={project.name + "'s screenshot"}
      href={project.img}
      target="_blank"
      rel="noreferrer"
      style={`background-image: url(${project.img.replace('.webp', '_thumb.webp')})`} />

    <!-- style={`background-image: url(${project.img})`} -->
    <!-- <img class="image" src={loading} /> -->
  {/if}

  <!-- info -->
  <div
    id={nameId(project.name)}
    class={`info 
          w-2/3 sm:w-full border-r border-b border-l border-grey-light lg:border-l-0 lg:border-t lg:border-grey-light bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal
          ${!project.img ? 'rounded' : ''}
        `}>
    <div>
      <!-- name -->
      <div class="project-type">
        {#if project.types && project.types.length > 0}
          <p class="text-grey-dark leading-none">
            {#each project.types as t, idx}
              <span class="bold">
                {t}{#if idx < project.types.length - 1}<span>/</span>{/if}
              </span>
            {/each}
            -{' '}
            <span class="bold"> {project.date.month}/{project.date.year} </span>
          </p>
        {/if}
      </div>
      <div class="name text-black font-bold text-xl mb-2">{project.name}</div>

      <!-- description -->
      <p class="description text-grey-darker text-base">
        {@html project.description}
      </p>
    </div>
    <div class="sections flex items-center">
      <div class="text-sm w-full">
        <!-- repositories -->
        {#if project.repos}
          <div class="section repos bg-yellow-lightest w-full p-2">
            <h5 class="title">
              Repositor{project.repos.length === 1 ? 'y' : 'ies'}
            </h5>
            {#each project.repos as repo, idx}
              <div class="information">
                <!-- icon -->
                {#if repo.url.indexOf('github.com') !== -1}
                  <svg viewBox="0 0 24 24">
                    <path d={mdiGithub} />
                  </svg>
                {:else}
                  <svg viewBox="0 0 24 24">
                    <path d={mdiWeb} />
                  </svg>
                {/if}

                <!-- repo url -->
                <a
                  href={repo.url}
                  rel="noreferrer"
                  target="_blank"
                  class="text-grey-dark inline no-underline">
                  <p class="pl-1 underline inline text-grey-darker">
                    {repo.name}
                  </p>
                  <p class="description">
                    {repo.description && repo.description.length > 0 ? ` -  ${repo.description}` : ''}
                  </p>
                </a>
              </div>
              {#if idx < project.repos.length - 1}
                <span>
                  <hr />
                </span>
              {/if}
            {/each}
          </div>
        {/if}

        <!-- videos -->
        {#if project.videos}
          <div class="section videos bg-grey-lightest w-full p-2">
            <h5 class="title">Video{project.videos.length > 1 ? 's' : ''}</h5>
            {#each project.videos as video, idx}
              <div class="information">
                <!-- icon -->
                <svg viewBox="0 0 24 24">
                  <path d={mdiVideo} />
                </svg>

                <!-- repo url -->
                <a
                  href={video[0]}
                  rel="noreferrer"
                  target="_blank"
                  class="text-grey-dark inline no-underline">
                  <p class="pl-1 underline inline text-grey-darker">
                    {video[1]}
                  </p>
                </a>
              </div>
              {#if idx < project.videos.length - 1}
                <span>
                  <hr />
                </span>
              {/if}
            {/each}
          </div>
        {/if}

        {#if project.screenshots}
          <div class="section screenshots bg-orange-lightest w-full p-2">
            <h5 class="title text-grey-dark pb-1">
              Screenshot{project.screenshots.length === 1 ? '' : 's'}
            </h5>
            {#each project.screenshots as screenshot, idx}
              <div class="information">
                <!-- icon -->
                <svg viewBox="0 0 24 24">
                  <path d={mdiGithub} />
                </svg>

                <!-- repo url -->
                <a
                  href={screenshot[0]}
                  rel="noreferrer"
                  target="_blank"
                  class="text-grey-dark inline no-underline">
                  <p class="pl-1 underline inline text-grey-darker">
                    {screenshot[1]}
                  </p>
                </a>
              </div>
              {#if idx < project.screenshots.length - 1}
                <span>
                  <hr />
                </span>
              {/if}
            {/each}
          </div>
        {/if}

        {#if project.url}
          <div class="section url bg-green-lightest w-full p-2">
            <h5 class="title">Website</h5>
            <div class="information">
              <svg viewBox="0 0 24 24">
                <path d={mdiWeb} />
              </svg>

              <a
                href={project.url}
                rel="noreferrer"
                target="_blank"
                class="text-grey-dark inline no-underline">
                <p class="pl-1 underline inline text-grey-darker">
                  {project.name}
                </p>
              </a>
            </div>
          </div>
        {/if}

        <!-- labels -->
        <div class="labels pt-4">
          {#if project.languages}
            {#each project.languages as language}
              <span
                class="label language text-grey-darkest bg-teal-lightest uppercase p-1 mr-1
                    rounded text-xs">
                {language}
              </span>
            {/each}
          {/if}

          {#if project.frameworks}
            {#each project.frameworks as framework}
              <span
                class="label framework text-grey-darkest bg-indigo-lightest uppercase p-1 mr-1
                    rounded text-xs">
                {framework}
              </span>
            {/each}
          {/if}

          {#if project.platforms}
            {#each project.platforms as platform}
              <span
                class="label platform text-grey-darkest bg-green-lightest uppercase p-1 mr-1
                    rounded text-xs">
                {platform}
              </span>
            {/each}
          {/if}

          <span class="types">
            {#if project.fork}
              <span
                class="label fork bg-blue-lighter text-xs m-1 p-1 text-grey-darkest
                float-right rounded">
                Fork
              </span>
            {/if}

            {#if project.open}
              <span
                class={`label ${project.open ? 'open' : 'closed'}
                text-xs m-1 p-1 text-grey-darkest float-right rounded
              `}>
                {project.open ? 'Open' : 'Closed'}
                Source
              </span>
            {/if}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
