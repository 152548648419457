<script>
  export let skillList = [];
  export let workTimeline = [];
  export let persoal_projects = [];

  export function secret() {
    return "lwomesAsftnbob/dpn"
      .split("")
      .map((c) => String.fromCharCode(c.charCodeAt() - 1))
      .join("")
      .toUpperCase();
  }

  // export function skills() {
  //   return skillList.sort((a, b) => b.stars - a.stars);
  // }

  import moment from "moment";
  import Timeline from "./Timeline.svelte";
  import reposj from "./repos.json";
  // import lozad from "lozad";
  import { onMount } from "svelte";

  // onMount(() => {
  //   const observer = lozad(); // lazy loads elements with default selector as '.lozad'
  //   observer.observe();
  // });

  export function parse(z) {
    return `${z.year === true ? "01" : "02"}/${z.date.month}/${z.date.year}`;
  }

  export let current_year = moment().year();

  // export let showSecret = false;
  export let github = [];
  export let repos = [];
  // export let showForks = true;
  export let reposTotal = 911;
  export let timeline = [];
  export let notSkills = [];
  export let projects = {};

  init();

  export function init() {
    github = [];

    const insert = (data) => {
      if (Array.isArray(data)) {
        data.forEach((i) => {
          github.push(i);
        });
      } else {
        github.push(data);
      }

      repos = github
        .filter(
          (repo) =>
            repo.description !== null &&
            (repo.language !== null || repo.name === "HighlanderFacil"),
        )
        .sort((repo, repo2) => repo2.stargazers_count - repo.stargazers_count);
    };

    console.log(reposj);
    Object.keys(reposj).forEach((k) => {
      insert(reposj[k]);
    });

    // let list = [
    //   "https://api.github.com/users/UnnoTed/repos",
    //   "https://api.github.com/users/ainstaller/repos",
    //   "https://api.github.com/users/noheadaches/repos",
    //   // "https://api.github.com/repos/KevinLadeira/HighlanderFacil",
    // ];

    // list.forEach((u) => {
    //   const data = localStorage.getItem(u);
    //   if (data !== null) {
    //     console.log("found in cache", u);
    //     insert(JSON.parse(data));
    //     return;
    //   }

    //   fetch(u)
    //     .then((response) => {
    //       response
    //         .json()
    //         .then((data) => {
    //           localStorage.setItem(u, JSON.stringify(data));
    //           insert(data);
    //         })
    //         .catch((err) => console.error(err));
    //     })
    //     .catch((err) => console.error(err));
    // });
  }
  export function showForks() {
    showForks = !showForks;
  }
  export function showSecret() {
    showSecret = true;
    secret = secret();
  }
  // export function secret() {
  //   if (showSecret) {
  //     return "lwomesAsftnbob/dpn"
  //       .split("")
  //       .map((c) => String.fromCharCode(c.charCodeAt() - 1))
  //       .join("")
  //       .toUpperCase();
  //   }

  //   return "";
  // }
  // export let parse;
  export function nameId(name) {
    if (name !== undefined) {
      // console.log("NAME", name);
      return name.replace(/\s/g, "_");
    }

    return name;
  }
  export function idName(id) {
    return id.replace(/_/g, " ");
  }
  export function skills() {
    return skillList.sort((a, b) => b.stars - a.stars);
  }
  export function buildprojects() {
    const blacklist = [
      "https://github.com/UnnoTed/renderbox",
      "https://github.com/ainstaller/aInstaller",
      "https://github.com/UnnoTed/Sensitivity-Tools",
      // "https://github.com/UnnoTed/Fumble",
      "https://github.com/UnnoTed/awesome-go",
      "https://github.com/UnnoTed/osu-web",
      "https://github.com/ainstaller/utils",
      "https://github.com/ainstaller/aupdater",
    ];
    const rs = repos.filter((repo) => {
      for (let i in blacklist) {
        if (blacklist[i] === repo.html_url) {
          return false;
        }
      }
      return true;
    });
    let github = [];
    for (let i in rs) {
      if (rs.hasOwnProperty(i)) {
        const repo = rs[i];
        const date = moment(repo.created_at);
        github.push({
          name: repo.name,
          description: repo.description,
          types: ["Hobby", "Personal"],
          open: true,
          fork: repo.fork,
          repos: [
            {
              name: repo.full_name,
              url: repo.html_url,
            },
          ],
          web: repo.homepage,
          languages: [repo.language],
          date: {
            year: date.year(),
            month: date.format("MMM"),
          },
        });
      }
    }

    let data = [].concat(github);
    data = data.concat(persoal_projects);
    data.forEach((repo, i) => {
      if (repo.name === "horizontal") {
        data[i].img = "https://cdn.resmana.com/file/tomate/kvn/horizontal.webp";
      } else if (repo.name === "godoc.org") {
        data[i].img = "https://cdn.resmana.com/file/tomate/kvn/godoc.webp";
      } else if (repo.name === "aestheticTed") {
        data[i].frameworks = ["Vue"];
        data[i].platforms = ["Chrome Extension"];
      } else if (repo.name === "wireguird") {
        data[i].img = "https://cdn.resmana.com/file/tomate/kvn/wireguird.webp";
        data[i].videos = [["https://streamable.com/dpthpr", "Preview"]];
        data[i].frameworks = ["GTK"];
        data[i].platforms = ["Linux"];
        data[i].description = `
        Wireguard GUI for Linux. (Work in progress)
        <br/>
        <br/>
        Features:
        <br/>
        - Looks almost the same as the windows wireguard client
        <br/>
        - Connection state icon
        <br/>
        - Reads configs from /etc/wireguard
        `;
      } else if (repo.name === "Fumble") {
        data[i].repos = [
          {
            name: "Fumble",
            url:
              "https://github.com/UnnoTed/Fumble/commit/1eb5124ada66a4978919faf7b8f01d013532ce33",
          },
        ];
      }
      // if (data[i].fork || data[i].name === "TF2 Stadium") {
      //   const suffix = "/commits?author=UnnoTed";
      //   data[i].repos.map((repo) => {
      //     if (repo.url.indexOf(suffix) === -1) {
      //       repo.url += suffix;
      //     }
      //     return repo;
      //   });
      // }
      let year = 0;
      if (repo.created_at) {
        year = Number(moment(repo.created_at).year());
      } else {
        year = repo.date.year;
      }
      let exists = false;
      for (let i in data) {
        if (data.hasOwnProperty(i)) {
          if (data[i].year && data[i].date.year === year) {
            exists = true;
            break;
          }
        }
      }
      if (!exists) {
        // data.push({
        //   year: true,
        //   date: { year, month: "Jan" },
        // });
      }
    });

    const all = data
      .sort((a, b) => {
        const t = "DD/MMM/YYYY";
        const au = moment(parse(a), t).unix();
        const bu = moment(parse(b), t).unix();
        return bu - au;
      })
      .filter((repo) => (!showForks ? !repo.fork : true));

    let list = {};

    all
      // .sort((a, b) => a.date.year - b.date.year)
      .forEach((p) => {
        if (!(p.date.year in list)) {
          list[p.date.year] = [];
        }

        list[p.date.year].push(p);
      });

    // list["now"] = [];
    return list;
  }

  projects = buildprojects();

  console.log(projects);

  export function get_projects(y) {
    return projects[y];
  }

  // import "./global.css";
</script>

<style global lang="scss">
  :root {
    --top-part-padding: 10px;
  }

  @media only screen and (max-width: 640px) {
    .info {
      flex-direction: column;

      .links {
        width: 100%;
        text-align: center;
      }
    }

    .skills {
      .skill {
        display: flex;
        flex-direction: column;
      }
      .skill > div > span {
        display: flex;
        flex-direction: column;

        > span {
          margin-top: 4px;
          margin-bottom: 4px;
        }
      }

      .skill-date {
        float: none;
      }
    }

    .ktimeline {
      h5.heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // content: attr(dataa);
        // color: red;

        > span:first-child {
          margin-right: 10px;
          background: rgba(0, 0, 0, 0.1);
        }

        > span:first-child,
        .type,
        .date {
          display: initial;
        }

        // > .separator {
        // display: none;
        // }
      }
    }

    div.project {
      flex-direction: column;

      > a.image {
        min-height: 200px;
        width: 100% !important;
        // width: 100%;
        // height: 200px;
      }

      .project-type {
        font-size: 8pt;
      }

      .project-type span.bold:last-child {
        display: none;
      }
    }
  }

  html,
  body {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    /* border: 1px solid #666; */
    background: #f6f5f0;
  }

  * {
    font-size: 0.98em;
    /* font-size: 8.9pt; */
  }

  .app {
    /* max-width: 194mm; */
    margin: 0 auto;
    /* margin-left: auto; */
    /*max-height: 272mm;*/

    max-width: 1100px;
    /* height: 297mm; */
    /* height: 282mm; */
    /* font-size: 10pt; */
    font-size: 1em;
    /* font-size: 0.98em; */
    background: #fff;
    overflow: visible;

    /* padding: 4mm; */
    /* border: 1px solid #666; */

    > div {
      background: rgb(255, 255, 255);
    }
  }

  body.dark-theme img {
    filter: brightness(0.8) contrast(1.2);
  }

  /* Or apply it via media query */
  // @media (prefers-color-scheme: dark) {
  //   $bg0: #282828;
  //   $bg0-hard: #1d2021;
  //   $bg0-soft: #32302f;
  //   $bg1: #3c3836;
  //   $bg2: #504945;
  //   $bg3: #665c54;
  //   $bg4: #7c6f64;

  //   // Dark Foreground
  //   $fg0: #fbf1c7;
  //   $fg1: #ebdbb2;
  //   $fg2: #d5c4a1;
  //   $fg3: #bdae93;
  //   $fg4: #a89984;

  //   // Dark Colors
  //   $dark-red: #cc241d;
  //   $dark-green: #98971a;
  //   $dark-yellow: #d79921;
  //   $dark-blue: #458588;
  //   $dark-purple: #b16286;
  //   $dark-aqua: #689d6a;
  //   $dark-orange: #d65d0e;
  //   $dark-gray: #928374;

  //   $light-red: #fb4934;
  //   $light-green: #b8bb26;
  //   $light-yellow: #fabd2f;
  //   $light-blue: #83a598;
  //   $light-purple: #d3869b;
  //   $light-aqua: #8ec07c;
  //   $light-orange: #f38019;
  //   $light-gray: #a89984;

  //   $background-main: #222;
  //   $background: #222;
  //   $text: #bbb;
  //   $text2: $fg4;
  //   $visited: $light-blue;

  //   .profile > div > .name,
  //   h5,
  //   h6 {
  //     color: $text2;
  //   }

  //   svg {
  //     fill: $text2;
  //   }

  //   html,
  //   body {
  //     background: $background;
  //     // &:hover {
  //     //   background: #222;
  //     // }
  //   }

  //   .links a {
  //     color: $text2 !important;
  //   }

  //   p.resume-link > a {
  //     background: $text;
  //     color: $background !important;

  //     &::before {
  //       color: $text;
  //     }
  //   }

  //   .timeline,
  //   .card {
  //     border: none !important;
  //   }

  //   .timeline .date {
  //     // background: $background !important;
  //     border-color: $text2 !important;
  //   }

  //   span.timeline__year.time {
  //     background: $text2;
  //     color: $background;
  //   }

  //   .timeline__group {
  //     border-color: transparent !important;

  //     &:hover {
  //       background: $text2 !important;
  //     }
  //   }

  //   .project,
  //   .project .image {
  //     border-color: $text2 !important;
  //   }

  //   .project {
  //     background: $background;

  //     .name {
  //       color: $text2;
  //     }

  //     .sections {
  //       p.description {
  //         color: $text !important;
  //       }
  //     }
  //   }

  //   a,
  //   a:link,
  //   a:visited {
  //     color: #fff;
  //   }

  //   a:visited {
  //     color: $visited;
  //   }

  //   .timeline div.timeline__group::after,
  //   .timeline .card::after {
  //     background-color: $text2 !important;
  //   }

  //   .label {
  //     border-color: $text2 !important;
  //   }

  //   .timeline .timeline__group,
  //   .timeline div.timeline__cards,
  //   .timeline div.date {
  //     background: $background;
  //   }

  //   img {
  //     filter: brightness(0.8) contrast(1.2);
  //   }

  //   .app.dark {
  //     background: transparent;
  //     color: $text;

  //     > div {
  //       background: $background-main;
  //       border: 1px dotted $text;
  //     }
  //   }

  //   .sections .section {
  //     &:hover {
  //       background: $background-main !important;
  //     }

  //     .information {
  //       &:hover {
  //         background: rgba(0, 0, 0, 0.4) !important;
  //       }
  //     }
  //   }

  //   // .block {
  //   //   background: #312c2c;
  //   //   border: 1px groove #333;
  //   //   outline: 1px solid #bbb;
  //   //   margin-top: 10px;
  //   //   padding: 10px;
  //   //   box-sizing: border-box;
  //   // }
  // }

  .resume-link {
    margin-top: 10px;

    a {
      position: relative;

      &::before {
        position: absolute;
        top: 8px;
        left: -25px;
        content: "->";
        color: black;
        // line-height: 32px;
        // font-size: 17px;
        font-weight: bold;

        animation-name: move;
        animation-duration: 2s;
        animation-iteration-count: infinite;
      }

      padding: 8px 12px;
      background: black;
      color: white;
      font-weight: bold;

      // animation-name: blink;
      // animation-duration: 4s;
      // animation-iteration-count: infinite;
    }
  }

  @keyframes blink {
    0% {
      background-color: black;
      color: white;
    }
    60% {
      background-color: white;
      color: black;
    }
  }

  @keyframes move {
    0% {
      left: -30px;
    }
    50% {
      left: -25px;
    }
    100% {
      left: -30px;
    }
  }

  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  p {
    margin: 0;
    padding: 0;
  }

  .top-part {
    /* display: flex; */
    /* flex-wrap: nowrap; */
  }

  .profile {
    width: 100%;
    padding-right: var(--top-part-padding);
    padding: 10px;
  }

  .profile h2 {
    font-size: 1.3em;
  }

  .profile > .about {
    /* margin-top: 5px; */
  }

  .skills {
    width: 100%;
    /* padding-left: var(--top-part-padding); */
    // padding: 10px;
  }

  .skill {
    border-bottom: 1px solid #ccc;
    padding: 8px 0px;
  }

  .skills .skill:last-child {
    border-bottom: none;
  }

  h6 {
    font-size: 0.8em;
  }

  hr {
    border: none;
    height: 1px;
    border-bottom: 1px solid #666;
  }

  .text-center {
    text-align: center;
  }

  .text-small {
    font-size: 0.8em;
    font-weight: normal;
  }

  .timeline-item {
    padding: 5px 0;
    border-bottom: 1px solid #ccc;
  }

  .ktimeline {
    padding: 10px;
  }

  .ktimeline .timeline-item:last-child {
    border-bottom: none;
  }

  .heading {
    display: flex;
  }

  .work-timeline > h5,
  .skills > div > h5 {
    /* color: red; */
    padding-bottom: 8px;
    border-bottom: 1px dotted #ddd;
  }

  .expander {
    flex-grow: 1;
    display: inline-block;
  }

  .timeline-item .heading > .date {
    padding-left: 5px;
    /* border-right: 1px solid #ccc; */
  }

  .timeline-item .heading > .type {
    padding-right: 5px;
    /* border-left: 1px solid #ccc; */
    font-style: italic;
  }

  .underline {
    text-decoration: underline !important;
    /* color: red; */
  }

  .info {
    display: flex;
    width: 100%;
  }

  .info > * {
    flex: 1;
    /* height: 80px; */
    margin: auto 0;
    justify-content: center;
  }

  .links {
    /* border: 1px solid red; */
    /* text-align: center; */
    text-align: right;
    /* display: flex; */
    /* flex-wrap: nowrap; */
    width: 100px;
    /* background: red; */
    /* margin-top: 10px; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    /* font-size: 10pt; */
  }

  .links > p {
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: normal;
    /* text-decoration: underline; */
  }

  .links > p:first-child > a {
    text-decoration: solid !important;
  }

  .links > p:nth-child(2) {
    /* text-align: center; */
    /* width: 100%; */
  }

  .skill-date {
    float: right;
    font-weight: normal;
    /* text-decoration: underline; */
    font-weight: bold;
  }

  .timeline-item .description {
    padding: 0;
    margin: 0;
  }

  ul {
    padding-left: 20px;
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .text-bold {
    font-weight: bold;
  }

  span.dab {
    padding: 0;
    margin-left: -20px;
  }

  .text-normal {
    font-weight: normal;
  }

  .text-underline {
    text-decoration: underline;
  }

  .text-italic {
    font-style: italic;
  }

  a {
    /* text-decoration: none; */
    color: black;
  }

  @media print {
    @page {
      size: A4; /* DIN A4 standard, Europe */
      margin: 0;
      /* border: 1px solid red; */
    }
    body {
      /* padding-top: 15mm; */
    }
  }

  @media print {
    .no-print,
    .no-print * {
      display: none !important;
    }
  }
</style>

<main>
  <div class="app dark">
    <div>
      <div class="top-part block">
        <div class="profile">
          <!-- profile -->
          <div>
            <!-- <img
              class="w-64 h-auto no-print"
              src="./assets/unnoted.png"
              alt="Avatar of Kevin L" /> -->
            <div class="name text-center">
              <h2>Kevin Ladeira</h2>
              <h6>Back-end software engineer</h6>
            </div>
          </div>

          <div class="info">
            <!-- about -->
            <div class="about">
              <!-- <div class="font-bold mt-8 ">About me</div> -->
              <ul>
                <li>
                  Experienced in building desktop apps, servers and websites.
                </li>
                <li>Programming for 13 years, most part as a hobby.</li>
                <li>Full-stack experience, focus on back-end.</li>
                <li>Located in Brazil, South America, UTC-3.</li>
                <li>Work in the company's timezone? if required.</li>
                <li>Relocate? Yes, visa required.</li>
                <!-- <li>Speaks English and Portuguese.</li> -->
              </ul>
              <!-- <p>
              Self-taught programmer, started programming at the age of 12 (25
              now), likes building desktop and server software, has experience
              with web front-end development but focus on the back-end,
              currently lives in Brazil but synchronize timezone or relocate
              Europe is highly considered, speaks Portuguese (native) and
              English (advanced).
            </p> -->
            </div>

            <div class="links">
              <p>
                <a class="mail" href={'mailto:' + secret()}> {secret()} </a>
              </p>
              <p>
                <a href="https://github.com/UnnoTed" target="_blank">
                  github.com/UnnoTed
                </a>
              </p>
              <!-- <p>
                <a href="https://kevin.resmana.com/" target="_blank">
                  kevin.resmana.com
                </a>
              </p> -->
              <p class="resume-link">
                <a
                  href="https://cdn.resmana.com/file/tomate/kvn/Kevin_Ladeira_backend_software_engineer.pdf"
                  target="_blank">
                  Resume/CV PDF
                </a>
              </p>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div class="skills block" style="margin-top: 10px;">
        <div>
          <h5 class="text-center">Programming experience</h5>

          <div style="padding: 10px;">
            {#each skills() as skill}
              <div class="skill">
                <div class="w-full text-left">
                  <span style="font-weight: bold;">
                    {skill.name}
                    <span class="skill-date">{skill.year}</span>
                  </span>
                </div>
                <div class="text-left w-full text-base normal-case mt-1">
                  {skill.description}
                </div>
              </div>
            {/each}
          </div>
        </div>
      </div>

      <hr />

      <!-- work timeline -->
      <div class="work-timeline block">
        <h5 class="text-center">
          Work experience
          <h6 class="text-small">Freelances</h6>
        </h5>
        <div class="ktimeline">
          <!-- dt express -->
          {#each workTimeline as job}
            <div class="timeline-item" id={job.company.replace(' ', '_')}>
              <div class="timeline-marker" />
              <div class="timeline-content">
                <h5 class="heading">
                  <span>{job.company}</span>
                  <span class="expander" />
                  <span class="type">{job.type}</span>
                  <span class="separator">/</span>
                  <span class="date">{job.date}</span>
                </h5>
                <div>
                  <span class="type">{job.role}</span>
                  <span class="type" style="float: right;">{job.tech}</span>
                </div>
                <div class="description">
                  {@html job.description}
                </div>
              </div>
            </div>
          {/each}
        </div>
      </div>
      <hr />
      <div class="work-timeline block">
        <h5 class="text-center">Personal projects</h5>
        <div class="timeline nzo-print">
          <div class="timeline__group">
            <span class="timeline__year time" aria-hidden="true"> Now </span>
          </div>
          {#each Object.keys(projects).reverse() as year}
            <div class="timeline__group">
              <!-- <hr /> -->
              <div class="timeline__cards">
                <Timeline data={projects[year]} />
              </div>
              <span class="timeline__year time" aria-hidden="true">
                {year}
                <span style="margin-left: 30px;">
                  {projects[year].length}
                  Project{projects[year].length === 1 ? '' : 's'}</span>
              </span>
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>
</main>
