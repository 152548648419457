<script>
  import Project from "./Project.svelte";
  export let project;
  export let data;
</script>

<style global lang="scss">
  .timeline {
    margin-left: 0 !important;
    display: grid;
    // max-width: calc(100vw - 200px);
    // .project {
    //   max-width: calc(100vw - 200px);
    // }
    // grid-row-gap: 20px;

    .text {
      display: inline-flex;
      // font-size:  1rem;
    }

    .time {
      display: inline-flex;
    }

    .time__month {
      margin-left: 4px;
    }

    .time {
      padding: 4px 20px 4px;
      background-color: #f0f0f0;

      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      color: currentColor;
    }

    .date {
      content: "";
      padding: 4px;
      // margin-top: calc(10% - 20px);
      background-color: white;
      border: 1px solid black;

      text-align: center;

      transform: translateY(-50%);

      position: absolute;
      top: 50%;
      left: -45px;
      z-index: 2;

      text-transform: uppercase;

      > span {
        font-size: 0.8em;
      }
    }

    .card {
      padding: none;
      margin-top: 0.8rem;
      position: relative;

      &::after {
        width: 14px;
        height: 1px;
        background: black;
        content: "";

        position: absolute;
        top: 50%;
        left: -14px;
        // z-index: 0;
      }
    }

    .card__content {
      margin-top: 8px;
    }

    .card {
      border-radius: 2px;
      border-left: 0px solid black;
      // box-shadow: var(
      //   --timelineCardBoxShadow,
      //   0 1px 3px 0 rgba(0, 0, 0, 0.12),
      //   0 1px 2px 0 rgba(0, 0, 0, 0.24)
      // );
      background-color: #fff;
    }

    .timeline__year {
      margin-top: 20px;
      margin-bottom: 20px; /* 1 */
    }

    .timeline__cards {
      display: grid;
      grid-row-gap: 8px;
    }

    // border-left: 1px solid black;
    // padding-top: 1rem;
    // padding-bottom: 1.5rem;

    .timeline__year {
      font-weight: bold;
      background: #fff;
      color: #333;
      border: 1px solid #333;
      margin-left: -1px;

      border: 1px dotted black;
      position: relative;
      // left: -25px;
      left: 22px;
      background: #333;
      color: #fff;
    }

    .timeline__card {
      position: relative;
      margin-left: 8px;
      margin-top: 25px;
      margin-right: 10px;

      $size: 4px;

      // border-left: $size groove black;
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
      // border-top: $size dotted black;
      // border-right: $size dotted black;
      // border-bottom: $size dotted black;
      margin-bottom: 20px;
      position: relative;
      left: -1px;
    }

    .timeline__cards {
      // overflow: hidden;
      // padding-top: 4px; /* 1 */
      // padding-bottom: 4px; /* 1 */
      margin-right: 8px;
      padding-left: 40px;
      background: #f6f5f0;
    }

    .timeline__card::before {
      content: "";
      width: 100%;
      height: var(--timelineCardLineWidth, 1px);
      background-color: var(
        --timelineCardLineBackgroundColor,
        var(--uiTimelineMainColor)
      );

      position: absolute;
      top: var(--timelineCardLineTop, 0px);
      left: -50%;
      z-index: -1;
    }

    .timeline__group {
      // $size: 4px;

      // border-left: $size groove black;
      // border-top-left-radius: 1px;
      // border-bottom-left-radius: 1px;
      // // border-top: $size dotted black;
      // // border-right: $size dotted black;
      // // border-bottom: $size dotted black;
      // margin-bottom: 20px;
      // position: relative;
      // left: -1px;

      // &:hover {
      // background: #f6f5f0;
      // }

      position: relative;
      border: 1px solid #f6f5f0;
      // border-top: 1px solid #fff;

      &:hover {
        $bg: #d7d8d9;
        background: $bg;
        // border: 1px solid red;
        border-color: $bg;
      }

      &::after {
        content: "";
        width: 1px;
        height: 100%;
        background-color: black;
        position: absolute;
        top: 0;
        left: 20px;
        z-index: 1;
      }
    }

    .timeline .timeline__group > hr {
      padding: 0;
      margin: 0;
      height: 0px;
      border-color: black;
    }
  }
</style>

<!--export const Project = (project) => (state, actions) => (-->
<!-- {#if project.year}
  <header class="timeline-header">
    <Project {project} />
  </header>
{:else}
  <div class="timeline-item">
    <Project {project} />
  </div>
{/if} -->
{#each data as project}
  <div class="timeline__card card">
    <div class="date">
      {project.date.month}<br /><span>{project.date.year}</span>
    </div>
    <Project {project} />
  </div>
{/each}
