import App from "./App.svelte";
import "bulma-timeline/dist/bulma-timeline.min.css";

// let current_year = new Date().getFullYear();
const app = new App({
  target: document.body,
  props: {
    github: [],
    repos: [],
    showForks: true,
    reposTotal: 911,
    // projects: [
    //   {
    //     name: "fileb0x",
    //     url: "https://github.com/UnnoTed/fileb0x",
    //     description: `
    //     <p style="margin-top: 5px;">A better customizable tool to embed files in Go by using a virtual file system and update embedded files remotely without restarting the server.</p>
    //     `,
    //     // <ul style="margin-top: 5px">
    //     //   <li>Designed and built a featurefull </li>
    //     //   <li>A real-time chat for employees.</li>
    //     //   <li>An encrypted file upload system.</li>
    //     //   <li>A compliance form generator that automatically inserts client's and employee's information like address, documents and signatures, it would then be printed automatically.</li>
    //     // </ul>
    //     type: "Open source",
    //     date: "2016",
    //     role: "Library",
    //     tech: "Golang",
    //   },
    //   // {
    //   //   name: "aInstaller v1",
    //   //   // url: "https://github.com/ainstaller/aInstaller",
    //   //   description: `
    //   //   <p style="margin-top: 5px;">First version of the desktop app to install and edit a custom Team Fortress 2's hud called ahud with a live preview of how it looks like in-game.</p>
    //   //   `,
    //   //   // <ul style="margin-top: 5px">
    //   //   //   <li>Designed and built a featurefull </li>
    //   //   //   <li>A real-time chat for employees.</li>
    //   //   //   <li>An encrypted file upload system.</li>
    //   //   //   <li>A compliance form generator that automatically inserts client's and employee's information like address, documents and signatures, it would then be printed automatically.</li>
    //   //   // </ul>
    //   //   type: "closed source",
    //   //   date: "2015",
    //   //   role: "desktop",
    //   //   tech: "C#, WPF",
    //   // },
    //   {
    //     name: "aInstaller v2",
    //     url: "https://github.com/ainstaller",
    //     description: `
    //     <p style="margin-top: 5px;">Desktop app to install and edit a custom Team Fortress 2's interface called ahud with a live preview of how it'll look in-game.</p>
    //     `,
    //     // <ul style="margin-top: 5px">
    //     //   <li>Designed and built a featurefull </li>
    //     //   <li>A real-time chat for employees.</li>
    //     //   <li>An encrypted file upload system.</li>
    //     //   <li>A compliance form generator that automatically inserts client's and employee's information like address, documents and signatures, it would then be printed automatically.</li>
    //     // </ul>
    //     type: "Open source",
    //     date: "2016",
    //     role: "Windows",
    //     tech: "Javascript, Electron, AngularJS, Golang",
    //   },
    // ],
    workTimeline: [
      {
        company: "DT Express",
        date: "Jun - Aug 2019",
        type: "Full-time",
        role: "Full-stack",
        tech: "Golang, PostgreSQL, Javascript, Vue",
        description: `
        <ul>
          <span class="dab">Designed and built:</span>
          <li>A panel for employees to manage international money transfers and customer's documents and signature.</li>
          <li>A real-time employee chat with Go and WebSockets.</li>
          <li>A compliance form generator utilizes client's and employee's information such as address, documents and signatures to generate a printable compliance form based on the amount of money that the client sent that month.</li>
        </ul>
          `,
        // <li>An encrypted file upload system.</li>
      },
      {
        company: "Engaged Hits",
        date: "Jul - Nov 2016",
        role: "Back-end",
        type: "Full-time including weekends",
        tech: "Golang, PostgreSQL, PHP, Javascript",
        description: `
        <ul>
          <li>
            Designed and built a huge algorithm(6k lines) to send organic instructions (navigate, click, sleep, type text) to a desktop and mobile app to complete tasks like
            account creation, watch videos, social networks likes, write comments and similar actions based on the user's information (country, device, browser marketshare).
          </li>
          <li>
            Designed and built user authentication, referral, support tickets and premium subscription system.
          </li>
          <li>Integrated Stripe's payments api and Sendinblue's email api.</li>
        </ul>

        <ul>
          <span class="dab">Was later hired as a front-end software engineer to fix part of the "PHP front-end" to allow the project to launch by:</span>
          <li>Moving api calls from php to javascript</li>
          <li>Moving user authentication implementation from php into javascript</li>
          <li>Implementing campaign creation, separate earnings, referral count and traffic percentage.</li>
        </ul>
        `,
      },
      {
        company: "CPaulSoft Group",
        date: "Jun - Jul 2016",
        role: "Back-end",
        type: "Full-time",
        tech: "Golang, MariaDB",
        description: `
        <ul>
          <li>
            Designed and built a CLI tool in Go to fix a huge Swagger spec to generate a rest api without type errors.
          </li>
          <li>
            Fixed micro-service real estate scraper bots and wrote tests.
          </li>
        </ul>
        `,
      },
      // {
      //   company: "Osu!",
      //   date: "Feb - Mar 2016",
      //   role: "front-end",
      //   type: "part-time",
      //   tech: "CoffeeScript, React, PHP",
      //   description: `
      //   <ul>
      //     <li>Converted the status, supporter and part of the changelog page from PSD to web page.</li>
      //   </ul>
      //   `,
      // },
      {
        company: "BlueBerry",
        date: "2013",
        role: "Full-stack",
        type: "Full-time",
        tech: "PHP, MySQL, JavaScript, jQuery",
        description: `
        <ul>
          <li>Designed and built a Wordpress plugin to manage product sales.</li>
          <li>Designed and built an online courses website.</li>
        </ul>
        `,
      },
    ],
    skillList: [
      {
        name: `Golang`,
        year: "2014 - present",
        // description: `CLI tools (code generators, scrapers, bots), REST APIs, real time servers, micro-services and desktop windows and linux programs.`,
        description: `CLI tools (Code Generators, Scrapers, Bots), REST APIs (Protobuf / JSON), real time servers (WebSockets), micro-services (RPC, GRPC) and desktop programs.`,
        refs: [
          "Engaged_Hits",
          "Visão_Concursos",
          "fileb0x",
          "DT_Express",
          "Risada",
          "World_Talk_Center",
          "wireguird",
        ],
      },
      {
        year: "2013 - present",
        name: "SQL, PostgreSQL, MySQL, MariaDB, MongoDB, SQLite",
        description: `Database application design, storing, manipulating and retrieving data.`,
        refs: [
          "Engaged_Hits",
          "Visão_Concursos",
          "TF2_Stadium",
          "authenticaTed",
        ],
      },
      {
        year: "2012 - present",
        name: "Javascript, Typescript, NodeJS, Electron, HTML, CSS",
        description: `Websites, single page web apps, scrapers and desktop windows programs.`,
        refs: [
          "Visão_Concursos",
          "Osu!",
          "Engaged_Hits",
          "aInstaller_v2",
          "DT_Express",
          "Risada",
        ],
      },
      // {
      //
      //   year: 2017,
      //   name: "TypeScript",
      //   description: `Front-end (Vue) in some of my personal projects.`,
      //   refs: ["Risada"],
      // },
      {
        name: "Python",
        year: "2014-2015, 2018",
        description: `Desktop windows programs with Qt.`,
        refs: ["renderb0x", "Sensitivity_Tools"],
      },
      {
        name: "C#",
        year: "2010-2011, 2015",
        description: `Desktop programs using WinForms and WPF.`,
        refs: ["aInstaller_v1", "Creeper_Tools", "PAWN_Gen"],
      },
      // {
      //   name: "Linux",
      //   year: 2014,
      //   description: `Ubuntu, Arch`,
      // },
      // {
      //
      //   year: 2010,
      //   name: "HTML, CSS",
      //   description: `Web pages`,
      //   refs: [
      //     "Visão_Concursos",
      //     "Osu!",
      //     "godoc.org",
      //     "Engaged_Hits",
      //     "aInstaller_v2",
      //   ],
      // },
    ],
    persoal_projects: [
      {
        name: "CityInfo",
        img: "https://cdn.resmana.com/file/tomate/kvn/cityinfo.webp",
        description: `
            Nomadlist.com's data but available for free. (Work in progress)
            <br/>
            You can see information about countries and apply lots of different filters to find the one you may like the most.
            <br/>
            <br/>
            Features:
            <br/>
            - No backend nor installation required
            <br/>
            - Free
          `,
        types: ["Hobby", "Personal"],
        open: false,
        repos: [
          {
            name: "CityInfo",
            description: "",
            url: "https://github.com/UnnoTed/cityinfo",
          },
          // [
          //   "https://cdn.resmana.com/file/tomate/kvn/wtc5.webp",
          //   "55mb ram usage",
          // ],
        ],
        // videos: [["https://cdn.resmana.com/file/tomate/kvn/", ""]],
        languages: ["Javascript"],
        frameworks: ["Vue", "Quasar"],
        platforms: ["Browser"],
        date: {
          year: 2020,
          month: "Oct",
        },
      },
      {
        name: "World Talk Center",
        img: "https://cdn.resmana.com/file/tomate/kvn/wtc3.webp",
        description: `
          Lightweight self-hosted (discord / slack) native privacy-focused alternative. (Work in progress)
          <br/>
          <br/>
          Features:
          <br/>
          - Voice channels (like: mumble, discord)
          <br/>
          - Portable, no installation needed
          <br/>
          - Custom avatars
          <br/>
          - Custom themes and layout modification
          <br/>
          - Embedded images
          <br/>
          - Custom user roles with separated channel permissions
          <br/>
          - File upload
          <br/>
          - Custom emoji
          <br/>
          - Markdown support
          <br/>
          - Only requires username and password for an account
          <br/>
          - Low resource usage (15-80mb ram), gpu rendering for high resolution monitors
          <br/>
          - The server runs in ARM devices with SQLite support
        `,
        types: ["Hobby", "Personal"],
        open: false,
        screenshots: [
          ["https://cdn.resmana.com/file/tomate/kvn/wtc.webp", "Light theme"],
          // [
          //   "https://cdn.resmana.com/file/tomate/kvn/wtc5.webp",
          //   "55mb ram usage",
          // ],
        ],
        // videos: [["https://cdn.resmana.com/file/tomate/kvn/", ""]],
        languages: ["Go"],
        // frameworks: ["Sciter"],
        platforms: ["Windows", "Linux", "OSX", "ARM"],
        date: {
          year: 2020,
          month: "Apr",
        },
      },
      {
        name: "DT Express",
        img: "https://cdn.resmana.com/file/tomate/kvn/dte3.webp",
        description: `
          A Panel to manage international money transfers.
          <br/>
          <br/>
          Features:
          <br/>
          - Store client's documents
          <br/>
          - Compliance form generation with employee's and client's signatures, montly cash limit and sender/receiver's documents and address.
          <br/>
          - Sign compliances automaticaly
          <br/>
          - Employee real-time chat
          <br/>
          <br/>
          <a href="#DT_Express">Click here for more info</a>
        `,
        types: ["Freelance", "Job"],
        open: false,
        // videos: [["https://cdn.resmana.com/file/tomate/kvn/", ""]],
        languages: ["Go", "Javascript"],
        frameworks: ["Echo", "Vue", "Quasar"],
        date: {
          year: 2019,
          month: "Jun",
        },
      },
      {
        name: "configuraTed",
        img: "https://cdn.resmana.com/file/tomate/kvn/configurated.webp",
        description: `
          Install Team Fortress 2's custom configs with a few clicks. (ABANDONED PROJECT)
          <br/>
          <br/>
          Features:
          <br/>
          - Backup current config
          <br/>
          - Speedtest before installing a custom config to adapt the network config
          <br/>
          - Disables current steam user's cloud save to avoid replacing the custom config
          <br/>
          - Sets the game to run at the maximum monitor's refresh rate
          <br/>
          - Multiple configs to choose (high performance, good graphics...)
          <br/>
        `,
        types: ["Hobby", "Personal"],
        open: true,
        languages: ["Python"],
        videos: [
          [
            "https://cdn.resmana.com/file/tomate/kvn/configurated.mp4",
            "Installation",
          ],
        ],
        repos: [
          {
            name: "configuraTed",
            description: "",
            url: "https://github.com/UnnoTed/configuraTed",
          },
        ],
        frameworks: ["Qt"],
        date: {
          year: 2019,
          month: "May",
        },
      },
      {
        name: "Risada",
        img: "https://cdn.resmana.com/file/tomate/kvn/risada.webp",
        description: `
          Rewrite of Brasileiragem (a 9gag copy for Brazilian memes) in an over-engineered way just to learn new tech: grpc, vue, minio, quasar and typescript.
          <br/>
          <br/>
          Features:
          <br/>
          - File upload into Minio or similar service (AWS S3)
          <br/>
          - User authentication
          <br/>
          - Image and video upload
          <br/>
          - Voting system (posts and comments)
          <br/>
          - Reddit scrapper bot
          <br/>
        `,
        types: ["Hobby", "Personal"],
        open: true,
        videos: [
          ["https://cdn.resmana.com/file/tomate/kvn/risada.mp4", "Risada"],
        ],
        repos: [
          {
            name: "risada",
            description: "old project, 9gag like website",
            url: "https://github.com/UnnoTed/risada",
          },
        ],
        languages: ["Go", "TypeScript", "Python"],
        frameworks: ["Vue", "GRPC", "Minio", "Quasar", "PostgreSQL"],
        platforms: ["Browser"],
        date: {
          year: 2019,
          month: "Mar",
        },
      },
      {
        name: "Discord alternative client",
        img: "https://cdn.resmana.com/file/tomate/kvn/discord.webp",
        description: `
          Lightweight native discord alternative client. (ABANDONED PROJECT)
          <br/>
          <br/>
          Features:
          <br/>
          - User list
          <br/>
          - Avatars
          <br/>
          - Roles
          <br/>
          - Image embed
          <br/>
          - Voice chat
        `,
        types: ["Hobby", "Personal"],
        open: false,
        // screenshots: [
        // ["https://cdn.resmana.com/file/tomate/kvn/wtc.webp", "Light theme"],
        // [
        //   "https://cdn.resmana.com/file/tomate/kvn/wtc5.webp",
        //   "55mb ram usage",
        // ],
        // ],
        // videos: [["https://cdn.resmana.com/file/tomate/kvn/", ""]],
        languages: ["Go"],
        // frameworks: ["Sciter"],
        platforms: ["Windows", "Linux"],
        date: {
          year: 2018,
          month: "Sep",
        },
      },
      {
        name: "aInstaller v2",
        img: "https://cdn.resmana.com/file/tomate/kvn/ainstaller.webp",
        description: "Interface customizer for team fortress 2",
        types: ["Hobby", "Personal"],
        open: true,
        languages: ["Javascript", "Golang"],
        frameworks: ["AngularJS"],
        platforms: ["NodeJS", "Electron"],
        videos: [
          [
            "https://cdn.resmana.com/file/tomate/kvn/ainstallerv2.mp4",
            "Preview",
          ],
        ],
        screenshots: [
          [
            "https://cdn.resmana.com/file/tomate/kvn/ainstaller_prototype.webp",
            "First prototype",
          ],
        ],
        repos: [
          {
            name: "aInstaller",
            description: "ainstaller for ahud",
            url: "https://github.com/ainstaller/aInstaller",
          },
          {
            name: "aInstaller's Utils",
            description: "Finds all steam's location in all partitions",
            url: "https://github.com/ainstaller/utils",
          },
          {
            name: "aUpdater",
            description: "checks for ahud updates",
            url: "https://github.com/ainstaller/aupdater",
          },
        ],
        date: {
          year: 2016,
          month: "Dec",
        },
        url: "https://github.com/ainstaller",
      },
      {
        name: "aInstaller v1",
        img: "https://cdn.resmana.com/file/tomate/kvn/ainstallerv1_main.webp",
        description: `Interface customizer for team fortress 2.
          <br/>
          It downloads the game's modified interface files and applies the changes that the user made, then copies the files into the game's directory.
          <br/>
          <br/>
          Features:
          <br/>
          - Live preview of interface elements (health, ammo, damage, crosshair...)
          <br/>
          - Live editing of interface elements
          <br/>
          - Automaticaly check for updates
          <br/>
          - Automaticaly update interface with user's changes
          `,
        types: ["Hobby", "Personal"],
        open: false,
        videos: [
          [
            "https://cdn.resmana.com/file/tomate/kvn/ainstaller1.mp4",
            "Changing Live HUD Colors",
          ],
        ],
        screenshots: [
          [
            "https://cdn.resmana.com/file/tomate/kvn/ainstaller_v1_preview.gif",
            "Preview GIF",
          ],
          [
            "https://cdn.resmana.com/file/tomate/kvn/ainstallerv1_main.webp",
            "Install / Update Tab",
          ],
          [
            "https://cdn.resmana.com/file/tomate/kvn/ainstallerv1_news.webp",
            "News Tab",
          ],
          [
            "https://cdn.resmana.com/file/tomate/kvn/ainstallerv1_crosshairs.webp",
            "Crosshairs Tab",
          ],
          [
            "https://cdn.resmana.com/file/tomate/kvn/ainstallerv1_colors.webp",
            "Colors Tab",
          ],
          [
            "https://cdn.resmana.com/file/tomate/kvn/ainstallerv1_settings.webp",
            "Settings Tab",
          ],
        ],
        languages: ["C#", "XAML"],
        frameworks: ["Caliburn.Micro", "MahApps.Metro"],
        platforms: [".NET", "Windows"],
        date: {
          year: 2015,
          month: "Sep",
        },
        url: "https://ainstaller.github.io/aInstaller/",
      },
      {
        name: "Engaged Hits",
        img: "https://cdn.resmana.com/file/tomate/kvn/engagedhits.webp",
        description: `I wrote the back-end and did some bug fixing and added features to the front-end for Engaged Hits.
          <br/>
          <a href="#Engaged_Hits">Click here for more info</a>`,
        types: ["Freelance", "Job"],
        open: false,
        languages: ["Javascript", "PHP", "Golang"],
        frameworks: ["jQuery"],
        platforms: ["Web"],
        date: {
          year: 2016,
          month: "Jun",
        },
        // url: "http://www.engagedhits.com/",
      },
      {
        name: "TF2 Stadium",
        img: "https://cdn.resmana.com/file/tomate/kvn/tf2stadium.webp",
        description: `Matchmaking for Team Fortress 2
          <br/> 
          - Designed and build a micro-service called Fumble in Golang to manage voice chat connections for online matches which used Mumble as a voice chat.
          <br/>
          - Helped writing a micro-service to setup matchmaking servers by executing gamemode configs, verifying if players are allowed in the current match and get player data from Steam API.

          <br/>
          Sadly the lack of communication from the team made me leave the project as things would get rewritten within days without any notice.`,
        types: ["Hobby", "Personal"],
        open: true,
        languages: ["Javascript", "Sass", "Golang"],
        frameworks: ["AngularJS", "Websockets", "PostgreSQL"],
        platforms: ["Browser"],
        date: {
          year: 2015,
          month: "Jul",
        },
        videos: [
          [
            "https://cdn.resmana.com/file/tomate/kvn/server_setup_and_configs.mp4",
            "Server config setup",
          ],
        ],
        repos: [
          {
            name: "Fumble",
            description: "The Mumble connection manager for TF2Stadium",
            url: "https://github.com/UnnoTed/Fumble",
          },
          {
            name: "Helen",
            description: "Server setup",
            url:
              "https://github.com/UnnoTed/Helen/commit/b5d3c2d4ed7b79dd76248b89278dcd04231bca48",
          },
          {
            name: "Helen",
            description: "Player stats",
            url:
              "https://github.com/UnnoTed/Helen/commit/285a33836af33c9c9e4fde4f418e3ea36959966b",
          },
          {
            name: "TF2RconWrapper",
            description: "Log parser",
            url:
              "https://github.com/UnnoTed/TF2RconWrapper/commit/4418ffdc64fdd60c534ba88dcaf58fc45f8bfcb2",
          },
          {
            name: "PlayerStatsScraper",
            description: "Player data from SteamAPI: GetPlayerSummaries",
            url:
              "https://github.com/UnnoTed/PlayerStatsScraper/commit/6056b1c84053afaf111ed711c560f40d194415f6",
          },
        ],
        // url: "https://www.tf2stadium.com/",
      },
      {
        name: "Visão Concursos",
        img: "https://cdn.resmana.com/file/tomate/kvn/visao.webp",
        description: "Prototype of a brazilian School's website",
        types: ["Freelance", "Job"],
        open: false,
        videos: [
          [
            "https://cdn.resmana.com/file/tomate/kvn/visao.mp4",
            "Visão's Prototype Panel",
          ],
          // ["https://youtu.be/5UXbNjAayYY", "Visão's Home Page Design"],
        ],
        languages: ["Javascript", "Golang"],
        frameworks: ["AngularJS", "Gin", "PostgreSQL"],
        platforms: ["Browser"],
        date: {
          year: 2016,
          month: "May",
        },
      },
      {
        name: "Osu!",
        img: "https://cdn.resmana.com/file/tomate/kvn/osustatus.webp",
        description:
          "I created the supporter and status pages, and wrote part of the changelog page for the new osu! (psd -> web).",
        types: ["Freelance", "Job"],
        open: false,
        fork: false,
        languages: ["Coffeescript", "Less", "PHP"],
        frameworks: ["React", "d3"],
        platforms: ["Browser"],
        screenshots: [
          ["https://cdn.resmana.com/file/tomate/kvn/osuuptime.gif", "uptime"],
        ],
        date: {
          year: 2016,
          month: "Feb",
        },
        // repos: [
        //   {
        //     name: "Osu! web",
        //     description: "the future face of osu!",
        //     url: "https://github.com/UnnoTed/osu-web",
        //   },
        //   {
        //     name: "Osu! change log",
        //     description: "unfinished",
        //     url:
        //       "https://github.com/UnnoTed/osu-web/commit/0eb9ccf2a744df9ed30c7fee807664cd1c539b2f#",
        //   },
        // ],
      },
      {
        name: "Crosshairb0x",
        img: "https://cdn.resmana.com/file/tomate/kvn/crosshairbox.webp",
        description:
          "crosshairb0x is a app that lets you use any image as a overlay crosshair in-game (even in fullscreen).",
        types: ["Hobby", "Personal"],
        open: false,
        screenshots: [
          [
            "https://cdn.resmana.com/file/tomate/kvn/crosshairbox2.webp",
            "Nicholas Cage",
          ],
        ],
        languages: ["Javascript", "C++"],
        frameworks: ["AngularJS"],
        platforms: ["Windows"],
        date: {
          year: 2015,
          month: "Sep",
        },
      },
      {
        name: "renderb0x",
        img: "https://cdn.resmana.com/file/tomate/kvn/renderbox.webp",
        description:
          "When i played Team Fortress 2 i used to make videos of it and the process was kinda boring, so i wrote a tool to automate the video compression... \
        The game would give me a sequence of files (a1_0001.jpg, a1_0002.jpg...) each being a video frame, the goal was to put then together and \
        set the fps automatically based on the time of the recorded audio and the amount of frames, into a .mp4 while being compressed through ffmpeg.\
        ",
        types: ["Hobby", "Personal"],
        open: true,
        languages: ["Python"],
        frameworks: ["Qt", "PyQt"],
        screenshots: [
          [
            "https://cdn.resmana.com/file/tomate/kvn/renderbox2.webp",
            "Render window",
          ],
        ],
        repos: [
          {
            name: "renderbox",
            description: "join image sequence into a video file",
            url: "https://github.com/UnnoTed/renderbox",
          },
        ],
        platforms: ["Windows"],
        date: {
          year: 2015,
          month: "Sep",
        },
      },
      {
        name: "Sensitivity Tools",
        img: "https://cdn.resmana.com/file/tomate/kvn/sensitivity_tools.webp",
        description:
          "When i played Team Fortress 2 i tried my best to find the perfect mouse sensitivity, so i wrote a tool to help me calculate, convert, generate raw zoom sensitivities and save a history of used sensitivities.",
        types: ["Hobby", "Personal"],
        open: true,
        languages: ["Python"],
        frameworks: ["Qt", "PyQt"],
        repos: [
          {
            name: "Sensitivity Tools",
            description: "tf2, quake, cs...",
            url: "https://github.com/UnnoTed/Sensitivity-Tools",
          },
        ],
        videos: [
          [
            "https://cdn.resmana.com/file/tomate/kvn/sensitivity_tools.mp4",
            "Sensitivty Tools v0.4",
          ],
        ],
        platforms: ["Windows"],
        date: {
          year: 2014,
          month: "Oct",
        },
      },
      {
        name: "Creeper Tools",
        img: "https://cdn.resmana.com/file/tomate/kvn/creepertools2.webp",
        description:
          "Creeper Tools is a program to downgrande and upgrade minecraft and Skin Downloader! you can download friend's skin and install on your account...",
        types: ["Hobby", "Personal"],
        open: false,
        languages: ["C#"],
        frameworks: ["Windows.Forms"],
        platforms: [".NET", "Windows"],
        date: {
          year: 2011,
          month: "Feb",
        },
        url:
          "https://www.minecraftforum.net/forums/mapping-and-modding-java-edition/minecraft-tools/1261652-creeper-tools-v0-2",
      },
      {
        name: "Visual PAWN",
        img: "https://cdn.resmana.com/file/tomate/kvn/visual_pawn.webp",
        description:
          "A editor with tabs and IDE like functions for pawn programming. *Abandoned",
        types: ["Hobby", "Personal"],
        open: true,
        languages: ["C#"],
        frameworks: ["Windows.Forms", "ScintillaNET"],
        platforms: [".NET", "Windows"],
        repos: [
          {
            name: "Source Code",
            description: "PAWN.zip",
            url: "https://cdn.resmana.com/file/tomate/kvn/PAWN.zip",
          },
        ],
        //   {
        //     name: "Debug",
        //     description: "Visual PAWN.rar",
        //     url: "https://kevin.resmana.com/data/Visual PAWN.rar",
        //   },
        // ],
        date: {
          year: 2011,
          month: "Sep",
        },
      },
      {
        name: "PAWN Gen",
        img: "https://cdn.resmana.com/file/tomate/kvn/pawngen.webp",
        description:
          "A tool to help create scripts for the San Andreas Multiplayer (SAMP) mod by generating code and giving details about assets ids.",
        types: ["Hobby", "Personal"],
        videos: [
          [
            "https://cdn.resmana.com/file/tomate/kvn/pawngen.mp4",
            "Pawn Gen demo",
          ],
        ],
        open: false,
        languages: ["C#"],
        frameworks: ["Windows.Forms"],
        platforms: [".NET", "Windows"],
        date: {
          year: 2010,
          month: "Feb",
        },
      },
      {
        name: "ezPR2 Trainer",
        img: "https://cdn.resmana.com/file/tomate/kvn/ezpr2trainer.webp",
        description: `A Simple trainer made for the flash game called Platform Racing 2, 
        the trainer would find the browser, run through all browser's processes until find the game's process, 
        then it would modify the memory on certain addresses to gain some habilities like fly (through cowboy hat), 
        become invencible (through crown hat), infinite items, and disable minimum rank for all levels.`,
        types: ["Hobby", "Personal"],
        open: true,
        videos: [
          [
            "https://cdn.resmana.com/file/tomate/kvn/ezpr2trainer.mp4",
            "The trainer in action",
          ],
        ],
        languages: ["LUA"],
        frameworks: ["Cheat Engine"],
        platforms: ["Windows"],
        repos: [
          {
            name: "Source code",
            url: "https://cdn.resmana.com/file/tomate/kvn/ezPR2_Trainer.lua",
          },
        ],
        date: {
          year: 2013,
          month: "Feb",
        },
      },
      {
        name: "NegociarFacil",
        img: "https://cdn.resmana.com/file/tomate/kvn/negociarfacil.webp",
        description: `A brazilian Amazon like website but only for brazilian registered companies.
        <br/>
        Features:
        <br/>
        - Automatic shipping tracking
        <br/>
        - Messaging between users (like email)
        <br/>
        - User reputation
        <br/>
        - Cart
        <br/>
        - Paid advertising
        <br/>
        - Filters: price, date, category, size, shipping price, location
        <br/>
        - Payment system: Paypal and PagSeguro integration
        <br/>
        - Product: question/answer, FAQ, review, timed discounts, region discount
        `,
        types: ["Hobby", "Personal"],
        open: false,
        languages: ["PHP", "Javascript", "CSS"],
        frameworks: ["jQuery", "MongoDB"],
        platforms: ["Browser"],
        screenshots: [
          [
            "https://cdn.resmana.com/file/tomate/kvn/negociarfacil.webp",
            "The only screenshot that i found",
          ],
        ],
        // url: "https://twitter.com/negociarfacil",
        date: {
          year: 2012,
          month: "Jun",
        },
      },
      {
        name: "Brasileiragem",
        description: `This was my first Go project, a 9gag copy for Brazilian memes.
          It had the features you would expect from a blog: users, posts, comments and a voting system for posts and comments.
          I lost the source code.
          `,
        types: ["Hobby", "Personal"],
        open: false,
        languages: ["Go", "Javascript", "CSS"],
        frameworks: ["AngularJS", "Gin", "MongoDB"],
        platforms: ["Browser"],
        date: {
          year: 2014,
          month: "Jul",
        },
        // url: "https://web.archive.org/web/20141217111343/http://kevinsl.net/",
      },
      {
        name: "Platform Racing 2 Cracked Client",
        img: "https://cdn.resmana.com/file/tomate/kvn/pr2net.webp",
        description: `
        I made a cracked client of the flash game called platform racing 2 by decompiling the original game and editing it, 
        it would give you all powers from the game's items (hats and guns), 
        and some other things like instant win.
  
        I also modified hettoo's perl bot to make all servers crash, which then i traded with quesin for some rank ups... 
        after a while, everyone was crashing servers and led me to a 6 years ban, that made my heart stop for some seconds.
        `,
        types: ["Hobby", "Personal"],
        open: false,
        languages: ["ActionScript"],
        platforms: ["Browser"],
        url:
          "https://web.archive.org/web/20110215000934/http://platformracing2.net:80/",
        videos: [
          [
            "https://cdn.resmana.com/file/tomate/kvn/pr2hacking2011.mp4",
            "Same cracked client on another website of mine",
          ],
          [
            "https://cdn.resmana.com/file/tomate/kvn/pr2alternativeflying.mp4",
            "Alternative flying type, without hat's powers...",
          ],
          [
            "https://cdn.resmana.com/file/tomate/kvn/pr2hacking.mp4",
            "40 MINUTES!!!",
          ],
        ],
        repos: [
          {
            name: "proof?",
            url: "https://archive.fo/Dck96",
          },
        ],
        date: {
          year: 2011,
          month: "Feb",
        },
      },
    ],
  },
});

export default app;
